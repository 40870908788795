import api from "@/assets/js/api";
// const { VUE_APP_UCENTER } = process.env;
import {
  VUE_APP_UCENTER,
  VUE_APP_APP,
  VUE_APP_ID,
  VUE_APP_KEY,
  VUE_APP_ENV,
} from "@/assets/js/stationSetting";

export function apiGetUserInfo(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Users/getUserInfo`,
    data,
  });
}
//获取手机验证码 (非登录态)
export function sendSignSmsCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/sendSignSmsCode`,
    data,
  });
}
//获取手机验证码 (非登录态-极验)
export function sendSignSmsCodeGeet(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Register/mobileCode`,
    data,
  });
}
//找回密码，发送短信
export function sendForgetSmsCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Forget/sms`,
    data,
  });
}

// // 获取手机验证码（二次校验）
// export function loginSendSmsCode(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/sendSmsCode`,
//     data,
//   });
// }
//获取手机验证码（二次校验）
export function loginSendSmsCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/LoginVerify/mobileCode`,
    data,
  });
}

// 获取手机验证码（完全登录态）
export function sendSmsCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Users/sendSmsCode`,
    data,
  });
}
// 获取邮箱验证码（非登录态）
export function sendSignEmailCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Account/sendSignEmailCode`,
    data,
  });
}
// 获取邮箱验证码（非登录态-极验）
export function sendSignEmailCodeGeet(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Register/emailCode`,
    data,
  });
}
// 获取邮箱验证码（非登录态-极验）
export function sendForgetEmailCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/Forget/email`,
    data,
  });
}

// // 获取邮箱验证码（二次校验）
// export function loginSendEmailCode(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/sendEmailCode`,
//     data,
//   });
// }
// 获取邮箱验证码（二次校验）
export function loginSendEmailCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/V3/UCenter/LoginVerify/emailCode`,
    data,
  });
}

// 获取邮箱验证码(完全登录态)
export function sendEmailCode(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Users/sendEmailCode`,
    data,
  });
}

// 修改密码
export function updatePassword(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/updatePassword`,
    data,
  });
}

// 绑定手机
export function bindMobile(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/bindMobile`,
    data,
  });
}

// 绑定邮箱
export function bindEmail(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/bindEmail`,
    data,
  });
}

// 修改绑定的手机
export function undataMobile(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/updateMobile`,
    data,
  });
}
// 获取谷歌令牌
export function getGoogleSecret(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/getGoogleSecret`,
    data,
  });
}

// 绑定谷歌令牌
export function bindGoogleVerify(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/bindGoogleVerify`,
    data,
  });
}

// 修改谷歌令牌
export function updateGoogleVerify(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/updateGoogleVerify`,
    data,
  });
}

// 关闭谷歌认证
export function closeGoogleVerify(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/closeGoogleVerify`,
    data,
  });
}

// 开启谷歌认证
export function openGoogleVerify(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/openGoogleVerify`,
    data,
  });
}

// 获取谷歌绑定状态
export function bingGoogleVerifyStatus(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/Security/bingGoogleVerifyStatus`,
    data,
  });
}
